/* eslint-disable @next/next/no-img-element */
import styles from "@buddieshr/styles/homePulsy.module.scss";
import MetaHeader from "@buddieshr/components/metaHeader";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Header from "@buddieshr/components/header";
import Footer from "@buddieshr/components/footer";
import TextColored from "@buddieshr/components/text/textColored";
import AddToSlackButton from "@buddieshr/components/addToSlackButton";
import PageWrapper from "@buddieshr/components/templates/pageWrapper";
import { Tag } from "antd";
import Features from "@buddieshr/components/app/features";
import Benefits from "@buddieshr/components/benefits";
import InstallNow from "@buddieshr/components/installNow";
import GetStarted from "@buddieshr/components/app/getStarted";
import Rated from "@buddieshr/components/rated";
import G2Logos from "@buddieshr/components/g2Logos";
import TrustedBy from "@buddieshr/components/trustedBy";
import Quote from "@buddieshr/components/templates/quote";
import Link from "next/link";
import Image from "next/image";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import PricingButtonHint from "@buddieshr/components/pricing/pricingbuttonHint";
import BuddieshrAppList from "@buddieshr/components/buddieshrAppList";
import LogoApp from "@buddieshr/components/app/logoApp";
import LovePanel from "@buddieshr/components/landing/lovePanel";

export default function Home() {
  const { t } = useTranslation();
  const router = useRouter();
  const app = PRODUCTS.find((c) => c.id === "Pulsy");

  return (
    <>
      <MetaHeader
        title={t("metaTitlePulsy")}
        description={t("descriptionContentPulsy")}
        currentURL={`https://buddieshr.com/${
          router.locale === "en" ? "" : router.locale
        }/${app.slug}`}
        currentShare={`https://buddieshr.com/img/apps/${app.slug}/share_preview_1.png`}
        pageSlug={`${app.slug}`}
        locale={router.locale}
      />
      <Header app={app} />
      <main className={styles.main} style={{ background: app.backgroundColor }}>
        <PageWrapper width={1500}>
          <div className={styles.firstSectionWrapper}>
            <LogoApp
              app={app}
              removeBack
              withText
              withSubtitle
              removeByBuddies
              wrapperStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 36,
                maxWidth: "100%",
              }}
              style={{
                textAlign: "left",
                margin: "auto",
                border: "2px solid #0000000a",
                borderRadius: "10px",
                padding: "6px 12px",
                paddingBottom: "8px",
                transform: "scale(1.2)",
                maxWidth: "calc(100% - 40px)",
              }}
            />
            <div className={styles.tagWrapper}>
              <Tag color="blue" className={styles.tag}>
                LISTEN AND MEASURE
              </Tag>
            </div>
            <h1 className={styles.mainTitle}>
              <TextColored app={app}>Measure</TextColored> eNPS, engagement and gather{" "}
              <TextColored app={app}>feedbacks</TextColored> in Slack
            </h1>
            <div className={styles.subtitle}>
              {
                "Broadcast polls and surveys where your team lives. Track eNPS over time. Get answers fast with automated reminders."
              }
            </div>
            <div className={styles.ratedWrapper}>
              <Rated />
            </div>
            <div className={styles.actions}>
              <AddToSlackButton app={app} />
              <div className={styles.hint}>
                <PricingButtonHint />
              </div>
            </div>
          </div>
        </PageWrapper>
        <TrustedBy />
        <br />
        <br />
        <br />
        <PageWrapper>
          <div className={styles.productPic}>
            <div className={styles.productPicInner}>
              <div className={styles.productPic1Wrapper}>
                <Image
                  src="/img/apps/pulsy/product/screen_answer_1.png"
                  width={511}
                  height={560}
                  alt="pulse survey in slack"
                  className={styles.pic}
                />
              </div>
              <div className={styles.productPic2Wrapper}>
                <Image
                  src="/img/apps/pulsy/product/screen_answer_2.png"
                  width={514}
                  height={382}
                  alt="enps in slack"
                  className={styles.pic}
                />
              </div>
            </div>
            <div className={styles.productPicInner}>
              <div className={styles.productPic3Wrapper}>
                <Image
                  src="/img/apps/pulsy/product/dashboard.png"
                  width={563 * 0.9}
                  height={572 * 0.9}
                  alt="dashboard answers in slack"
                  className={styles.pic}
                />
              </div>
            </div>
          </div>
        </PageWrapper>
        <div>
          <Quote>
            <b>Promote a listening based culture</b>
            <br />
            <Link
              href="https://www2.deloitte.com/content/dam/Deloitte/global/Documents/HumanCapital/gx-dup-global-human-capital-trends-2016.pdf"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              Deloitte research
            </Link>{" "}
            shows that employees value culture
            <br /> over compensation and benefits and value a listening based
            culture.
          </Quote>
          <br />
          <br />
          <br />
        </div>
        <PageWrapper>
          <div className={styles.benefitsWrapper}>
            <Benefits app={app} />
          </div>
          <G2Logos />
          <LovePanel showStars small filterKeyword="pulsy" />

          <div className={styles.featureWrapper} id="features">
            <Features app={app} />
          </div>
          <br />
          <br />

          <GetStarted app={app} />
          <div className={styles.installWrapper}>
            <InstallNow
              app={app}
              gradient={app.gradient}
              title="Ready to gather unique insights from your team?"
              subtitle=" Get started now and measure your eNPS today."
            />
          </div>
        </PageWrapper>
        <div>
          <BuddieshrAppList appToHide={app.slug} />
        </div>
      </main>
      <Footer app={app} />
    </>
  );
}
